import { StrapiMediaType } from "@/common";
import { AppLoader } from "@/components/AppLoader";
import { Display } from "@/components/Display";
import Box from "@mui/material/Box";
import { ImgHTMLAttributes, useCallback, useMemo, useState } from "react";

const databaseUrl = process.env.REACT_APP_DATABASE_URL;

type ImagePropsType = ImgHTMLAttributes<NonNullable<unknown>> & {
  image?: StrapiMediaType;
  defaultLocalImage?: string;
  placeholder?: boolean;
};
export const Image = ({ style, image, defaultLocalImage, placeholder = true, ...props }: ImagePropsType) => {
  const [isLoading, setIsLoading] = useState(true);

  const imageProps: (isVisible: boolean, isDefault?: boolean) => ImgHTMLAttributes<NonNullable<unknown>> = useCallback(
    (isVisible, isDefault) => ({
      style: {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        objectPosition: "center",
        position: isDefault ? "unset" : "absolute",
        top: 0,
        borderRadius: "inherit",
        bottom: 0,
        right: 0,
        left: 0,
        opacity: isVisible ? 1 : 0,
        ...style,
      },
      ...props,
    }),
    [props, style],
  );

  const imagePath = useMemo(() => {
    if (!databaseUrl) {
      return "";
    }
    return databaseUrl + image?.data?.attributes?.url;
  }, [image]);

  const thumbnailPath = useMemo(() => {
    if (!databaseUrl) {
      return "";
    }

    return databaseUrl + image?.data?.attributes.formats.thumbnail.url;
  }, [image]);

  if (!image?.data && defaultLocalImage) {
    return (
      <img
        src={defaultLocalImage}
        {...imageProps(true, true)}
        alt={"thumbnail"}
      />
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        borderRadius: "inherit",
      }}
    >
      <img
        onLoad={() => setIsLoading(false)}
        src={imagePath}
        {...imageProps(!isLoading || !placeholder)}
        alt={props.alt || ""}
        loading={"lazy"}
      />
      <img
        src={thumbnailPath}
        {...imageProps(isLoading && placeholder)}
        loading={"lazy"}
        alt={props.alt || ""}
      />
      <Display condition={isLoading}>
        <AppLoader />
      </Display>
    </Box>
  );
};
